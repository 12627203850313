<template>
    <b-modal :ref="modalRef" hide-header hide-footer hide-backdrop centered @hidden="onHidden">
        <b-col class="text-center" sm="10" offset-sm="1">
            <h1>Félicitations !</h1>
            <p>Vous avez gagné l'objet suivant :</p>
            <div class="collected-item">
                <b-img :src="requireItemImage(itemType)" alt="Image de l'objet" class="collectable-item-img"/>
                <h3>{{ collectableItem.nom }}</h3>
                <div v-html="sanitize(collectableItem.description || '')"></div>
            </div>
            <p><small> <em>
                Toutes vos récompenses sont visibles sur votre page de profil
            </em></small></p>
            <b-button @click="hideModal">Merci !</b-button>
        </b-col>
    </b-modal>
</template>

<script>
    import {sanitize} from 'dompurify';

    import modalMixin                  from '@/mixin/modalMixin';
    import {getItem, requireItemImage} from '@/util/collectableItem';

    export default {
        name: "ItemCollectionModal",
        mixins: [modalMixin],
        props: {
            itemType: {
                type: String,
                required: true
            }
        },
        data: () => ({
            modalRef: 'itemCollectionModal',
            collectableItem: {}
        }),
        methods: {
            sanitize,
            requireItemImage,
            onHidden() {
                this.$store.dispatch('fireworks/stop').then(() => this.destroyModal());
            }
        },
        mounted() {
            this.collectableItem = getItem(this.itemType);
            this.$store.dispatch('fireworks/trigger');
        }
    }
</script>

<style scoped>
    .collected-item {
        margin-top: 20px;
        padding-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-radius: 20px;
        border: 1px solid #eee;
    }
</style>